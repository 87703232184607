import { FC, useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, Grid, CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { moneyFormat } from "../../../toolbox/helpers/money-helpers";
import { useRenditionBuildingStore } from "../../../hooks/useRenditionBuildingStore";
import CustomTable from "../../../components/common/CustomTable/CustomTable";

interface ModalBuildingDepartmentProps {
    open: boolean;
    closeModal: () => void;
    renditionSelected: { id: string } | null;
}
const columns = [
    { type: "text", field: "description", label: "DESCRIPCION", align: "center" },
    { type: "text", field: "building", label: "EDIFICIO", align: "center" },
    {
        type: "text",
        field: "previous_month_balance",
        label: "DEUDA DEL MES ANTERIOR",
        align: "center",
        format: (row) => moneyFormat(row.previous_month_balance, "$"),
    },
    {
        type: "text",
        field: "favor_month_balance",
        label: "SALDO A FAVOR",
        align: "center",
        format: (row) => moneyFormat(row.favor_month_balance, "$"),
    },
    {
        type: "text",
        field: "amount_individual",
        label: "TOTAL INDIVIDUAL",
        align: "center",
        format: (row) => moneyFormat(row.amount_individual, "$"),
    },
    {
        type: "text",
        field: "amount_mes",
        label: "DEUDA DEL MES",
        align: "center",
        format: (row) => moneyFormat(row.amount_mes, "$"),
    }, 
    {
        type: "text",
        field: "total_pay",
        label: "TOTAL A PAGAR",
        align: "center",
        format: (row) => moneyFormat(row.total_pay, "$"),
    },
    { type: "detail", field: "detail", label: "", align: "center" },
];

export const ModalBuildingDepartment: FC<ModalBuildingDepartmentProps> = ({ open, closeModal, renditionSelected }) => {
    const { getDetailRendicionAll, getRenditionDetailPDF } = useRenditionBuildingStore();
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [formattedData, setFormattedData] = useState<any[]>([]);
    
    useEffect(() => {
        if (renditionSelected?.id) {
            getDataDepartments(renditionSelected.id);
        }
    }, [renditionSelected]);

    const formatRenditionData = (dataArray) => {
        if (!Array.isArray(dataArray)) return [];

        return dataArray.map((data) => ({
            description: data.department.department_name,
            building: data.department.building,
            subtotal: data.data.general.total,
            amount_individual: data.data.individual.total,
            amount_mes: data.data.subtotal,
            previous_month_balance: data.data.saldoMesAnterior,
            favor_month_balance : data.data.saldoFavorMesAnterior,
            total_pay: data.data.total_pagar,
            total_paid: data.data.total_pagado,
            iddepartment: data.department.iddepartment,
            amount_balance: data.data.balanceMes,
        }));
    };

    const getDataDepartments = async (id) => {
        try {
            setLoading(true);
            const response = await getDetailRendicionAll({ idreport_building: id });
            console.log("Response:", response);

            if (response && Array.isArray(response)) {
                const formatted = formatRenditionData(response);
                setFormattedData(formatted);
            }
        } catch (error) {
            console.error("Error al obtener la rendición:", error);
        } finally {
            setLoading(false);
        }
    };

    const onHelper = async (rowSelected) => {
        setDownloading(true);
        try {
            const parsedId = renditionSelected?.id ? parseInt(renditionSelected.id, 10) : 0;
            const response = await getRenditionDetailPDF(parsedId, rowSelected.iddepartment);
            if (response !== false) {
                const blob = new Blob([response], { type: "application/pdf" });
                const bloblURL = URL.createObjectURL(blob);
                window.open(bloblURL, "_blank");
            }
        } finally {
            setDownloading(false);
        }
    };

    return (
        <Dialog open={open} onClose={closeModal} maxWidth="lg" fullWidth>
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    sx={{ position: "absolute", right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {loading ? (
                    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: 200 }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <CustomTable
                            title={"Listado de rendiciones de departamentos"}
                            columns={columns}
                            rows={formattedData}
                            onRowClick={() => {}}
                            loading={downloading}
                            hasOptions
                            onHelper={onHelper}
                            icon_helper={<PictureAsPdfIcon fontSize="small" />}
                            tooltip_helper={"Ver PDF"}
                        />
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    );
};
