import { FC, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { useNavigate, useParams } from "react-router-dom";
import { moneyFormat } from "../../toolbox/helpers/money-helpers";
import { useRenditionBuildingStore } from "../../hooks/useRenditionBuildingStore";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { UploadTranfersBuildingModal } from "../RenditionBuilding/components/UploadTransferBuildingModal";
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const columns = [
    { type: "options", field: "options", label: "", align: "center" },
    { type: "text", field: "description", label: "DESCRIPCION", align: "center" },
    { type: "text", field: "building", label: "EDIFICIO", align: "center" },
    {
        type: "text",
        field: "previous_month_balance",
        label: "DEUDA DEL MES ANTERIOR",
        align: "center",
        format: (row) => moneyFormat(row.previous_month_balance, "$"),
    },
    {
        type: "text",
        field: "favor_month_balance",
        label: "SALDO A FAVOR",
        align: "center",
        format: (row) => moneyFormat(row.favor_month_balance, "$"),
    },
    {
        type: "text",
        field: "amount_individual",
        label: "TOTAL INDIVIDUAL",
        align: "center",
        format: (row) => moneyFormat(row.amount_individual, "$"),
    },
    {
        type: "text",
        field: "amount_mes",
        label: "DEUDA DEL MES",
        align: "center",
        format: (row) => moneyFormat(row.amount_mes, "$"),
    },
    {
        type: "text",
        field: "total_pay",
        label: "TOTAL A PAGAR",
        align: "center",
        format: (row) => moneyFormat(row.total_pay, "$"),
    },
    {
        type: "text",
        field: "total_paid",
        label: "TOTAL PAGADO",
        align: "center",
        format: (row) => moneyFormat(row.total_paid, "$"),
    },
    {
        type: "text",
        field: "amount_balance",
        label: "BALANCE MES",
        align: "center",
        format: (row) => moneyFormat(row.amount_balance, "$"),
    },
    { type: "detail", field: "detail", label: "", align: "center" },
];

export const RenditionBuildingDepartmentList: FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const {
        sendMailPDF,
        getDetailRendicionAll,
        getRenditionDetailPDF,
    } = useRenditionBuildingStore();
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [mailLoading, setMailLoading] = useState(false);
    const [mailsLoading, setMailsLoading] = useState(false);

    const [formattedData, setFormattedData] = useState<any[]>([]);
    const [showModalTransfer, setShowModalTransfer] = useState<boolean>(false)

    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [allSelected, setAllSelected] = useState<boolean>(false);

    useEffect(() => {
        if (id) {
            getDataDepartments();
        }
    }, [id]);

    const formatRenditionData = (dataArray) => {
        if (!Array.isArray(dataArray)) return [];

        return dataArray.map((data) => ({
            description: `Rendición de ${data.department.department_name} - ${data.department.title}`,
            building: data.department.building,
            subtotal: data.data.general.total,
            amount_individual: data.data.individual.total,
            amount_mes: data.data.subtotal,
            previous_month_balance: data.data.saldoMesAnterior,
            favor_month_balance : data.data.saldoFavorMesAnterior,
            total_pay: data.data.total_pagar,
            total_paid: data.data.total_pagado,
            iddepartment: data.department.iddepartment,
            amount_balance: data.data.balanceMes,
        }));
    };

    const getDataDepartments = async () => {
        try {
            const response = await getDetailRendicionAll({ idreport_building: id });
            console.log("Response:", response);

            if (response && Array.isArray(response)) {
                const formatted = formatRenditionData(response);
                setFormattedData(formatted);
            }
        } catch (error) {
            console.error("Error al obtener la rendición:", error);
        }
    };

    const onHelper = async (rowSelected) => {
        setDownloading(true);
        try {
            const parsedId = id ? parseInt(id, 10) : 0
            const response = await getRenditionDetailPDF(parsedId, rowSelected.iddepartment);
            if (response !== false) {
                const blob = new Blob([response], { type: "application/pdf" });
                const bloblURL = URL.createObjectURL(blob);
                window.open(bloblURL, "_blank");
            }
        } finally {
            setDownloading(false);
        }
    };

    const onAdd = async () => {
        setShowModalTransfer(true)
    }

    const closeModalTransfer = async () => {
        setShowModalTransfer(false);
        await getDataDepartments();
    }

    const onMailing = async(rowSelected) => {
        setMailLoading(true)
        const payload = {
            iddepartments : [rowSelected.iddepartment],
            idreport_building: id
        }
        await sendMailPDF(payload)
        setMailLoading(false)
        setSelectedRows([]);
        setAllSelected(false);
    }

    const onMailingMasive = async() => {
        setMailsLoading(true)
        const iddepartments = selectedRows.map((item) => item.iddepartment);
        const payload = {
            iddepartments : iddepartments,
            idreport_building: id
        }
        await sendMailPDF(payload)
        setMailsLoading(false)
        setSelectedRows([]);
        setAllSelected(false);
    }

    const onCheckbox = (row: any) => {
        const selectedIndex = selectedRows.findIndex(selectedRow => selectedRow.iddepartment === row.iddepartment);
        let newSelected:any = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }
        setSelectedRows(newSelected);
        setAllSelected(newSelected.length === formattedData.length);

    };

    const selectAllRows = () => {
        if (allSelected) {
            setSelectedRows([]);
            setAllSelected(false);
        } else {
           setSelectedRows(formattedData);
            setAllSelected(true);
        }
    };

    const isSelected = (row: any) => selectedRows.some(selectedRow => selectedRow.iddepartment === row.iddepartment);


    return (
        <>
            {downloading && <CustomBackdrop open={true} />}
            {mailLoading && <CustomBackdrop open={true} />}
            {mailsLoading && <CustomBackdrop open={true} />}
            <Grid item xs={12}>
                <CustomTable
                    title={"Listado de rendiciones de departamentos"}
                    columns={columns}
                    rows={formattedData}
                    onRowClick={() => { }}
                    loading={loading}
                    hasOptions
                    isSelected={isSelected}
                    onCheckbox={onCheckbox}
                    selectAll={selectAllRows}
                    allSelected={allSelected}
                    onAddFn={onAdd}
                    onAddFnName={'REGISTRAR ABONO'}
                    onAddFn2={onMailingMasive}
                    onAddFnName2={'ENVIAR CORREOS'}
                    disabledAdd2={selectedRows.length == 0}
                    onHelper={onHelper}
                    icon_helper={<PictureAsPdfIcon fontSize="small" />}
                    tooltip_helper={"Ver PDF"}
                    onHelper2={onMailing}
                    icon_helper2={<MailOutlineIcon fontSize="small" />}
                    tooltip_helper2={"Enviar mail"}
                />
            </Grid>
            {
                showModalTransfer && (
                    <UploadTranfersBuildingModal
                        open={showModalTransfer}
                        closeModal={closeModalTransfer}
                        isAdmin={true}
                        isPayment={true}
                    />
                )
            }
        </>
    );
};
