import { api } from "./configs/axiosConfigs"

export const PeriodAPI = {
    getPeriods: async (data?: any) => {
        const response = await api.get(
        '/period',
        {
            params: {
                ...data
            }
        }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    }
}