import { api } from "./configs/axiosConfigs"

export const RenditionBuildingAPI = {
  getOrCreate: async (data: any, idcompany: number) => {
    const response = await api.post(
      '/reportBuilding/getOrCreate', 
      {
        ...data,
        idcompany
      }
    ).then(response => response.data)
    .catch((error) => {
      console.error(error)
      return error.response.data
    })
    return response
  },
  getRenditions: async (idcompany: number, data?: any) => {
      const response = await api.get(
      '/reportBuilding',
      {
          params: {
              ...data,
              idcompany
          }
      }
      ).then(response => response.data)
      .catch((error) => {
          console.error(error)
          return error.response.data
      })
      return response
  },
  create: async (idcompany: number, data: any) => {
      const response = await api.post(
          '/reportBuilding/register', 
          {
              ...data,
              idcompany
          }
      ).then(response => response.data)
      .catch((error) => {
          console.error(error)
          return error.response.data
      })
      return response
  },
    sendMailPDF: async (idcompany: number, data: any) => {
        const response = await api.post(
            '/reportBuilding/sendMailPDF',
            {
                ...data,
                idcompany
            }
        ).then(response => response.data)
            .catch((error) => {
                console.error(error)
                return error.response.data
            })
        return response
    },
  edit: async (id: number, data: any) => {
      const response = await api.patch(
      `/reportBuilding/${id}`,
      {
          ...data
      }
      ).then(response => response.data)
      .catch((error) => {
          return error.response.data
      })
      return response
  },
  delete: async (id: number, idcompany: number) => {
      const response = await api.delete(
      `/reportBuilding/${id}`,
      {
          params: {
              idcompany
          }
      }
      ).then(response => response.data)
      .catch((error) => {
          return error.response.data
      })
      return response
  },
  getRenditionDetailPDF: async (idreport_building:number, iddepartment:number, idcompany:number) => {
      const response = await api.get(
      '/reportBuilding/detailRendicionPDF',
      {
          params: {
              idreport_building,
              iddepartment,
              idcompany
          },
          responseType: 'blob'
      }
      ).then(response => response)
      .catch((error) => {
          console.error(error)
          return error.response
      })
      return response
  },
  getRenditionBuildingDetailPDF: async (idreport_building:number, idcompany:number) => {
      const response = await api.get(
      '/reportBuilding/detailRendicionBuildingPDF',
      {
          params: {
              idreport_building,
              idcompany
          },
          responseType: 'blob'
      }
      ).then(response => response)
      .catch((error) => {
          console.error(error)
          return error.response
      })
      return response
  },
  getRenditionDetail: async (idreport_building:number, iddepartment:number, idcompany:number) => {
      const response = await api.get(
      '/reportBuilding/detailRendicion',
      {
          params: {
              idreport_building,
              iddepartment,
              idcompany
          },
          responseType: 'blob'
      }
      ).then(response => response)
      .catch((error) => {
          console.error(error)
          return error.response
      })
      return response
  },
  getById: async (id:number) => {
      const response = await api.get(
      `/reportBuilding/${id}`
      ).then(response => response.data)
      .catch((error) => {
          console.error(error)
          return error.response.data
      })
      return response
  },
  closeRendition: async (id:number, idcompany:number) => {
      const response = await api.post(
        `/reportBuilding/validate/${id}`,
        {
          idcompany
        }
      ).then(response => response.data)
      .catch((error) => {
        console.error(error)
        return error.response.data
      })
      return response
  },
  editTotal: async (id:number) => {
    const response = await api.patch(
      `/reportBuilding/total/${id}`
    ).then(response => response.data)
    .catch((error) => {
      console.error(error)
      return error.response.data
    })
    return response
},
  getRenditionBuildingOwner: async (data: any) => {
      const response = await api.get(
      '/reportBuilding/detailCategoryRendicion',
      {
          params: {
              ...data,
          }
      }
      ).then(response => response.data)
      .catch((error) => {
          console.error(error)
          return error.response.data
      })
      return response
  },
  getDetailRendicion: async (data: any) => {
    const response = await api.get(
    '/reportBuilding/detailRendicion',
    {
        params: {
            ...data,
        }
    }
    ).then(response => response.data)
    .catch((error) => {
        console.error(error)
        return error.response.data
    })
    return response
},
  getDetailRendicionAll: async (data: any) => {
    const response = await api.get(
    '/reportBuilding/detailRendicionAll',
    {
        params: {
            ...data,
        }
    }
    ).then(response => response.data)
    .catch((error) => {
        console.error(error)
        return error.response.data
    })
    return response
},
}