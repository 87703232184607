import React from 'react';
import { Button, ButtonGroup, Container, Grid, IconButton, Checkbox, InputAdornment, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, Menu, MenuItem, Tooltip } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { withStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import PaidIcon from '@mui/icons-material/Paid'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart'
import CancelIcon from '@mui/icons-material/Cancel'
import HelpIcon from '@mui/icons-material/Help'
import '../../../assets/styles/pewen-app.css'

const CustomTable = (props:any)=> {

  const { columns=[], rows=[], loading=false, hasOptions } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //icon btn
  const [openMenu, setOpenMenu] = React.useState<any>(null);
  const handleOpen = (event) => {
    event.preventDefault();
    setOpenMenu(event.currentTarget);
  };

  const navigate = useNavigate()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  return (
    <>
      <Grid container>
        {
          !props?.disabled_title && (
            <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom:'10px'}}>
              <Typography variant='h6' component='h1' gutterBottom sx={{ mt: 1, ml:1}} className='title-main'>
                {props.title && (props.title).toUpperCase()}
              </Typography>
            </Grid>
          )
        }
        <Grid item xs={12} container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom:'10px'}}>
          {
            props.onSearch && (
              <Grid item>
                <TextField
                  InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                    )
                  }}
                  placeholder="Buscar..."
                  size="small"
                />
              </Grid>
            )
          }
          {
            props.onAdd && (
              <Grid item xs={!(props.onSearch) && 12} sx={{ textAlign: 'right' }}>
                <Button
                  onClick={() =>  navigate({ pathname: props.onAdd })}
                  className="btn-table"
                  sx={{ mt: "10px"}}
                >
                  {'Agregar'}
                </Button>
              </Grid>
            )
          }
          {
            props.onAddFn && (
              <Grid item xs={!(props.onSearch) && 12} sx={{ textAlign: 'right' }}>
                <Button
                  onClick={() => props.onAddFn() }
                  className="btn-table"
                  sx={{ mt: "10px"}}
                  disabled={props.disabledAdd ? props.disabledAdd : false}
                >
                  { props.onAddFnName ? props.onAddFnName : 'Agregar' }
                </Button>
                {
                  props.onAddFn2 && (

                    <Button
                      onClick={() => props.onAddFn2()}
                      className="btn-table"
                      sx={{ mt: "10px", ml: "5px" }}
                      disabled={props.disabledAdd2 ? props.disabledAdd2 : false}
                    >
                      {props.onAddFnName2 ? props.onAddFnName2 : 'Agregar'}
                    </Button>
                  )
                }
              </Grid>
            )
          }
        </Grid>
      </Grid>
        
      <TableContainer>
        <Table>
          <TableHead sx={{background: '#D8E8F7'}}>
            <TableRow >
              {columns.map((column:any, i:any) => (
                <StyledTableCell className="headTable" key={i} align='center'>
                {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
            <TableBody>
            {
              loading ? (
                <TableRow>
                  {
                    columns.map((row:any,i:any) => {
                      if (i === columns.length - 1) {
                        return null;
                      }
                      return (
                        <TableCell key={i}>
                          <Skeleton sx={{ height: 20 }} animation="wave" variant="rectangular"/>
                        </TableCell>
                      )
                    })
                  }
                </TableRow>
              ) : (
                rows.length > 0 ? (
                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any, i:any) => {
                  const isItemSelected = props.isSelected && props.isSelected(row);
                  return (
                    <>
                    <TableRow hover role="checkbox" tabIndex={-1} key={i} onClick={() => { props.onRowClick(row) } } style={{cursor:'pointer'}}>
                      {
                        columns.map((column, i2) => (
                          <>
                            {
                              column.type == "options" && hasOptions ?  (
                              <TableCell className="cellTable" key={i2} align={column.align} style={{fontSize:"13px"}}>
                                <ButtonGroup variant="contained" aria-label="button-group-custom-table" style={{padding: "0px 5px", color:"#808080"}}>
                                    {
                                      props.onCheckbox && (
                                        <Checkbox
                                          color="primary"
                                          size="small"
                                          checked={isItemSelected}
                                          onChange={() => props.onCheckbox(row)}
                                        />
                                      )
                                    }
                                  {
                                    props.onEdit && (
                                      <Tooltip title={'Editar'}>
                                      <IconButton size="small" color="inherit" aria-label="edit" onClick={() => { props.onEdit(row) }}>
                                        <EditIcon fontSize='small' />
                                      </IconButton>
                                      </Tooltip>
                                    )
                                  }
                                  {
                                    props.onCancel && (
                                      <Tooltip title={'Cancelar/Anular'}>
                                      <IconButton size="small" color="inherit" aria-label="cancel" onClick={() => { props.onCancel(row) }}>
                                        <CancelIcon fontSize='small' />
                                      </IconButton>
                                      </Tooltip>
                                    )
                                  }
                                  {
                                    props.onDelete && (
                                      <Tooltip title={'Eliminar'}>
                                      <IconButton size="small" color="inherit" aria-label="delete" onClick={() => { props.onDelete(row) } }>
                                        <DeleteOutlineIcon fontSize='small' />
                                      </IconButton>
                                      </Tooltip>
                                    )
                                  }
                                  {
                                    props.onUpload && (
                                      <IconButton size="small" color="inherit" aria-label="upload" onClick={() => { props.onUpload(row) } }>
                                        <FileUploadIcon fontSize='small' />
                                      </IconButton>
                                    )
                                  }
                                  {
                                    props.onCheckIcon && props.onClearIcon && (
                                      row.validation_status === '0'
                                      ?
                                      <>
                                      <Tooltip title="Confirmar pago">
                                        <IconButton size="small" color="inherit" aria-label="upload" onClick={() => { props.onCheckIcon(row) } }>
                                          <CheckIcon fontSize='small' />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Rechazar pago">
                                        <IconButton size="small" color="inherit" aria-label="upload" onClick={() => { props.onClearIcon(row) } }>
                                          <ClearIcon fontSize='small' />
                                        </IconButton>
                                      </Tooltip>
                                      </>
                                      :
                                      null
                                    )
                                  }
                                  {/* {
                                    props.onCheckIcon && (
                                      <IconButton size="small" color="inherit" aria-label="upload" onClick={() => { props.onCheckIcon(row) } }>
                                        <CheckIcon fontSize='small' />
                                      </IconButton>
                                    )
                                  }
                                  {
                                    props.onClearIcon && (
                                      <IconButton size="small" color="inherit" aria-label="upload" onClick={() => { props.onClearIcon(row) } }>
                                        <ClearIcon fontSize='small' />
                                      </IconButton>
                                    )
                                  } */}
                                </ButtonGroup>
                              </TableCell>
                              ) : (
                                column.type == "detail" ? (
                                  <TableCell className="cellTable" key={i2} align={column.align} style={{fontSize:"13px"}}>
                                    <ButtonGroup variant="contained" aria-label="button-group-custom-table" style={{padding: "0px 5px", color:"#808080"}}>
                                        {
                                          props.onSwitch && (
                                            <Switch defaultChecked color="success" />
                                          )
                                        }
                                        {
                                          props.onDetails && (
                                            <Tooltip title={'Ver'}>
                                            <IconButton size="small" color="inherit" aria-label="view" onClick={() => { props.onDetails(row) }}>
                                              <VisibilityIcon fontSize='small' />
                                            </IconButton>
                                            </Tooltip>
                                          )
                                        }
                                        {
                                          props.onBtnMenu && (
                                            <>
                                              <Tooltip title={props?.tooltip_menu ? props?.tooltip_menu : ''}>
                                              <IconButton size="small" color="inherit" aria-label="view" onClick={handleOpen}>
                                                {props?.iconBtnMenu ? props?.iconBtnMenu : <StackedBarChartIcon fontSize="small" />}
                                              </IconButton>
                                              </Tooltip>
                                              <Menu
                                                id="basic-menu"
                                                open={Boolean(openMenu)}
                                                anchorEl={openMenu}
                                                onClose={()=>{setOpenMenu(null)}}
                                                MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                                }}
                                              >
                                                {
                                                  props.options && (props.options).length > 0 && props.options.map((item) => (
                                                    <MenuItem key={item.id} onClick={()=>{item.action(row)}}>{item.name}</MenuItem>
                                                  ))
                                                }
                                              </Menu>
                                            </>
                                          )
                                        }
                                        {
                                          props.onPaidIcon && (
                                            <Tooltip title="Ver pagos">
                                              <IconButton color="inherit" size="medium" onClick={() => { props.onPaidIcon(row) }}>
                                                <PaidIcon fontSize='medium' />
                                              </IconButton>
                                            </Tooltip>
                                          )
                                        }
                                        {
                                          props.onHelper && (
                                            <Tooltip title={props?.tooltip_helper ? props?.tooltip_helper : ''}>
                                            <IconButton size="small" color="inherit" aria-label="view" onClick={() => { props.onHelper(row) }}>
                                              {props?.icon_helper ? props.icon_helper : <HelpIcon fontSize="small" />}
                                            </IconButton>
                                            </Tooltip>
                                          )
                                        }
                                        {
                                          props.onHelper2 && (
                                            <Tooltip title={props?.tooltip_helper2 ? props?.tooltip_helper2 : ''}>
                                            <IconButton size="small" color="inherit" aria-label="view" onClick={() => { props.onHelper2(row) }}>
                                              {props?.icon_helper2 ? props.icon_helper2 : <HelpIcon fontSize="small" />}
                                            </IconButton>
                                            </Tooltip>
                                          )
                                        }
                                        {
                                          props.onHelper3 && (
                                            <Tooltip title={props?.tooltip_helper3 ? props?.tooltip_helper3 : ''}>
                                            <IconButton size="small" color="inherit" aria-label="view" onClick={() => { props.onHelper3(row) }}>
                                              {props?.icon_helper3 ? props.icon_helper3 : <HelpIcon fontSize="small" />}
                                            </IconButton>
                                            </Tooltip>
                                          )
                                        }
                                    </ButtonGroup>
                                  </TableCell>
                                ) : (
                                  <TableCell className="cellTable" key={i2} align={column.align}>
                                    {column.format ? column.format(row) : row[column.field] || ""}
                                  </TableCell>
                                )
                              )
                            }
                          </> 
                        ))
                      }
                    </TableRow>
                    </>
                  );
                })
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns ? columns.length : 1}>
                      <Grid sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                      <div>{"Sin data"}</div>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ) 
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 100]}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}

export default CustomTable