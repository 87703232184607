import { useSelector } from "react-redux"
import { useDispatch } from "../redux/store"
import { changeStatus, onFetchPeriod, selectPeriodState } from "../redux/slices/periodSlice"
import { ApiStatus } from "../types/api/status"
import { PeriodAPI } from "../apis/PeriodAPI"

export const usePeriodStore = () => {
  const dispatch = useDispatch()
  const { periods, selectedPeriod, status } = useSelector(selectPeriodState)

  const getPeriods = async (data?: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await PeriodAPI.getPeriods(data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
      }
      dispatch(onFetchPeriod(response.data))
      dispatch(changeStatus(ApiStatus.FETCHED))
      return response.data
    } catch (error) {
      console.error(error)
      dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  return {
    //states
    periods,
    selectedPeriod,
    status,
    //actions
    getPeriods
  }
}