import { useSelector } from "react-redux"
import { useDispatch } from "../redux/store"
import { ApiStatus } from "../types/api/status"
import { RenditionBuildingSpentAPI } from "../apis/RenditionBuildingSpentAPI"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { RenditionSpent } from "../types/slices/renditionSpentType"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"
import { changeStatus, onFetchRenditionBuildingSpent, onSetSelectedRenditionBuildingSpent, selectRenditionBuildingSpentState } from "../redux/slices/renditionBuildingSpentSlice"

export const useRenditionBuildingSpentStore = () => {
  const dispatch = useDispatch()
  const { renditionBuildingSpents, selectedRenditionBuildingSpent, status } = useSelector(selectRenditionBuildingSpentState)

  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany
  
  const getRenditionBuildingSpents = async (data?: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await RenditionBuildingSpentAPI.getRenditionBuildingSpents(idcompany, data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data
      dispatch(onFetchRenditionBuildingSpent(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      console.error(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const createRenditionBuildingSpent = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await RenditionBuildingSpentAPI.create(data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "")
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "")
      return true
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const insertBeforeSpent = async (id:number, data?: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await RenditionBuildingSpentAPI.insertBeforeSpent(id, data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "")
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "")
      return true
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }


  const editRenditionBuildingSpent = async (id: number, data: any) => {
    try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await RenditionBuildingSpentAPI.edit(id, data)
        if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || "");
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message  || "");
        return true
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
}

  const deleteRenditionBuildingSpent = async (id: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await RenditionBuildingSpentAPI.delete(id, idcompany)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      return true
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const setSelectedRenditionBuildingSpent = (renditionSpent: RenditionSpent) => {
    try {
        dispatch(onSetSelectedRenditionBuildingSpent(renditionSpent))
    } catch (error) {
        console.log(error)
    }
  }

  return {
    //states
    status,
    renditionBuildingSpents,
    selectedRenditionBuildingSpent,
    //actions
    getRenditionBuildingSpents,
    createRenditionBuildingSpent,
    insertBeforeSpent,
    editRenditionBuildingSpent,
    deleteRenditionBuildingSpent,
    setSelectedRenditionBuildingSpent,
  }

}